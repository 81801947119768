<template>
	<b-card>
		<template v-if="!hideStoreDetails">
			<store-image-and-name :store="order.store" />
			<hr />
		</template>

		<b-row align-h="between" class="mx-2">
			<!-- Order -->
			<p class="font-weight-bold">Order #{{ orderData.id }}</p>

			<!-- Amount -->
			<p v-if="hasItemWithNoPrice">{{ orderAmount | rupeeCurrency }} + {{ "**" | rupeeCurrency }}</p>
			<p v-else>{{ orderAmount | rupeeCurrency }}</p>
		</b-row>

		<b-row align-h="between" class="mx-2">
			<!-- Item Count -->
			<p>{{ itemCountText }}</p>

			<!-- Date -->
			<p>{{ createdTimeDate }}</p>
		</b-row>

		<hr />

		<b-row align-h="between" align-v="center" class="mx-2">
			<!-- Order Status -->
			<div class="text-capitalize">
				{{ orderData.orderStatus | orderStatusToDisplay }}
			</div>

			<!-- Details Button -->
			<b-button @click="onDetailsClicked" variant="info">
				Details
				<b-icon-chevron-right />
			</b-button>
		</b-row>
	</b-card>
</template>

<script>
import dateformat from "dateformat";
import storeImageAndName from "../../components/store/StoreImageAndName.vue";

export default {
	components: { storeImageAndName },
	props: ["order", "hideStoreDetails"],
	// props: {
	// 	order: {
	// 		type: Object,
	// 		required: true,
	// 	},
	// },
	created() {
		this.orderData = { ...this.order };
	},
	data() {
		return {
			orderData: {
				id: 0,
				orderStatus: "",
				totalItemsPrice: 0,
				deliveryCost: 0,
				orderAmount: 0,
				createdTime: 0,
				itemCount: 0,
				hasItemWithNoPrice: false,
			},
		};
	},
	computed: {
		createdTimeDate() {
			const date = new Date(this.orderData.createdTime);
			return dateformat(date, process.env.VUE_APP_DATE_TIME_FORMAT);
		},
		itemCountText() {
			const itemCount = this.orderData.itemCount;
			if (itemCount) {
				if (itemCount == 1) {
					return "1 item";
				}
				return `${itemCount} items`;
			}
			return "No items";
		},
		hasItemWithNoPrice() {
			return this.orderData.hasItemWithNoPrice;
		},
		orderAmount() {
			return this.orderData.orderAmount;
		},
		store() {
			return this.order.store;
		},
	},
	methods: {
		onDetailsClicked() {
			this.$emit("detailsClicked", this.order);
		},
	},
};
</script>

<style scoped></style>
