<template>
	<b-row>
		<b-col>
			<b-card class="border-0">
				<b-card-title>
					<div class="d-flex justify-content-between align-items-center">
						<span>Orders</span>
						<user-order-store-id-selector v-model="selectedStoreId" @change="onDropdownChange" />
					</div>
				</b-card-title>

				<template v-if="hideStoreDetails">
					<store-image-and-name :store="selectedStore" />
				</template>

				<!-- List of Orders -->
				<template v-if="orders.length > 0">
					<b-row>
						<b-col cols="12" sm="6" lg="4" v-for="order of orders" :key="order.id">
							<order-summary-card :order="order" :hideStoreDetails="hideStoreDetails" @detailsClicked="detailsClicked" class="mb-3" />
						</b-col>
					</b-row>

					<!-- Pagination -->
					<div>
						<b-pagination v-model="currentPage" :total-rows="count" :per-page="perPageCount" @change="onPaginationChange" align="center" />
					</div>
				</template>

				<!-- No Orders -->
				<template v-else>
					<b-alert show variant="info" class="text-center">
						<h4 class="alert-heading">No Orders Placed!</h4>
						<p>You haven't placed any order yet.</p>
					</b-alert>
				</template>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import OrderSummaryCard from "../../../components/orders/OrderSummaryCard";
import UserOrderStoreIdSelector from "../../../components/orders/UserOrderStoreIdSelector.vue";
import StoreImageAndName from "../../../components/store/StoreImageAndName.vue";

export default {
	components: { OrderSummaryCard, UserOrderStoreIdSelector, StoreImageAndName },
	created() {
		this.initList();
	},
	data() {
		return {
			selectedStoreId: null,

			// for pagination
			currentPage: 1,
			perPageCount: 12,
			count: 0,
		};
	},
	computed: {
		...mapGetters({
			orders: "user/orders/getOrders",
		}),
		hideStoreDetails() {
			if (this.selectedStoreId) {
				return true;
			}
			return false;
		},
		paginationParams() {
			const storeIds = this.selectedStoreId;
			const page = this.currentPage - 1;
			const size = this.perPageCount;
			const sort = "id,desc"; // for sorting by id desc
			return { storeIds, page, size, sort };
		},
		selectedStore() {
			if (this.orders.length > 0) {
				return this.orders[0].store;
			}
			return null;
		},
	},
	methods: {
		...mapActions({
			getOrdersCount: "user/orders/getOrdersCount",
			getOrdersApi: "user/orders/getOrders",
		}),
		initList() {
			this.getOrdersCount(this.paginationParams).then((data) => {
				const count = data.count;
				this.count = count;
				if (count) this.getOrders();
			});
		},
		getOrders() {
			this.getOrdersApi(this.paginationParams);
		},
		onPaginationChange(page) {
			// first changing the current page
			this.currentPage = page;
			// calling order
			this.getOrders();
		},

		onDropdownChange() {
			this.initList();
		},

		detailsClicked(order) {
			this.$router.push({
				name: "UserOrderDetail",
				params: { orderId: order.id },
			});
		},
	},
};
</script>
