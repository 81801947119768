<template>
  <div class="ml-5">
    <b-form-select
      v-model="selected"
      :options="options"
      value-field="id"
      text-field="storeName"
      @input="onInput"
      @change="onChange"
    >
      <template #first>
        <b-form-select-option :value="null"> All </b-form-select-option>
      </template>
    </b-form-select>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["value"],
  created() {
    this.getUniqueOrderStores().then((data) => {
      this.options = [...data];
    });
  },
  data() {
    return {
      selected: this.value ? this.value : null,
      options: [],
    };
  },
  methods: {
    ...mapActions({
      getUniqueOrderStores: "user/orders/getUniqueOrderStores",
    }),
    onInput() {
      this.$emit("input", this.selected);
    },
    onChange() {
      this.$emit("change", this.selected);
    },
  },
};
</script>
